import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enContent from "./locale/en.json";
import arContent from "./locale/ar.json";

// Get the language from local storage or use the language of the user's device
const savedLanguage = localStorage.getItem("language");
const userLanguage = savedLanguage || navigator?.languages?.[1];

const resources = {
	en: {
		translation: enContent,
	},
	ar: {
		translation: arContent,
	},
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		lng: userLanguage,
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: true,
		},
	});

export default i18n;
