import { createSlice } from "@reduxjs/toolkit";

import { SectorSolutionsThunk } from "../Thunk/SectorSolutionsThunk";
import { ShowOneSectorThunk } from "../Thunk/ShowOneSectorThunk";

const initState = {
	reload: true,
	sectorSolutionsData: [],
	currentPage: {},
};

const SectorSolutionsReducer = createSlice({
	name: "SectorSolutions",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// =======AllPagesThunk data===========
			.addCase(SectorSolutionsThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(SectorSolutionsThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.sectorSolutionsData = action.payload?.data;
			})
			.addCase(SectorSolutionsThunk.rejected, (state, action) => {
				state.reload = false;
			})

			// =======Show one Sector data===========
			.addCase(ShowOneSectorThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(ShowOneSectorThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.currentPage = action.payload?.data;
			})
			.addCase(ShowOneSectorThunk.rejected, (state, action) => {
				state.reload = false;
			});
	},
});

export default SectorSolutionsReducer.reducer;
