import React from "react";
import ReactLoading from "react-loading";

const LoadingData = () => {
	return (
		<section
			style={{ zIndex: 9999 }}
			className=' absolute  w-full h-screen bg-main_color flex justify-center items-center'>
			<ReactLoading type={"cylon"} color='#fff' />
		</section>
	);
};

export default LoadingData;
