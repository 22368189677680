import { createSlice } from "@reduxjs/toolkit";

import { SendContactUsThunk } from "../Thunk/SendContactUsThunk";

const initState = {
	reload: true,
	message: "",
};

const ContactUsReducer = createSlice({
	name: "ContactUs",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// =======AllPagesThunk data===========
			.addCase(SendContactUsThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(SendContactUsThunk.fulfilled, (state, action) => {
				state.reload = false;

				state.servicesData = action.payload?.data;
				state.message = action.payload?.message;
			})
			.addCase(SendContactUsThunk.rejected, (state, action) => {
				state.reload = false;
			});
	},
});

export default ContactUsReducer.reducer;
