import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./NotFoundPage.css";

const NotFoundPage = () => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();

	return (
		<div className='NotFoundPage'>
			<section className='page_404'>
				<div className='container'>
					<div className='row'>
						<div className='col-sm-12'>
							<div className='col-sm-10 col-sm-offset-1 text-center w-100'>
								<div className='four_zero_four_bg'>
									<h1 className='text-center'>404</h1>
								</div>
								<div className='contant_box_404'>
									<h3 className='h2'>
										{i18n.language === "ar" ? "يبدو أنك تائه" : "You seem lost"}
									</h3>
									<p>
										{i18n.language === "ar"
											? "الصفحة التي تبحث عنها غير متوفرة!"
											: "The page you're looking for is not available!"}
									</p>
									<button className='link_404' onClick={() => navigate("/")}>
										{i18n.language === "ar" ? "الصفحة الرئيسية" : "Home Page"}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default NotFoundPage;
