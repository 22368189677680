import { createSlice } from "@reduxjs/toolkit";
import { AboutUsThunk } from "../Thunk/AboutUsThunk";

let initState = {
	reload: false,
	aboutUsData: {},
};

const AboutUsReducer = createSlice({
	name: "AboutUs",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(AboutUsThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(AboutUsThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.aboutUsData = action.payload.data;
			})
			.addCase(AboutUsThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.error.message;
			});
	},
});
export default AboutUsReducer.reducer;
