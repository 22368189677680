import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const ShowOneProjectThunk = createAsyncThunk(
	"Projects/ShowOneProjectThunk",
	async (arg, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;
		try {
			const url = `https://api.raj.com.sa/api/public/projects/${arg}`;
			const res = await axios.get(url, {
				headers: {
					Locale: localStorage.getItem("language") || navigator?.languages?.[1],
				},
			});

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data.data);
		}
	}
);
