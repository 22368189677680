import { createSlice } from "@reduxjs/toolkit";
import { PartnersPageThunk } from "../Thunk/PartnersPageThunk";

const initState = {
	reload: true,
	partnersData: [],
};

const PartnersReducer = createSlice({
	name: "Partners",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// =======AllPagesThunk data===========
			.addCase(PartnersPageThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(PartnersPageThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.partnersData = action.payload?.data;
			})
			.addCase(PartnersPageThunk.rejected, (state, action) => {
				state.reload = false;
			});
		// =======OnePageThunk data===========
	},
});

export default PartnersReducer.reducer;
