import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import "./i18n/i18n";
// App Pages Routes
import { Router } from "./routes/Routes";

// main CSS styles
import "./index.css";

/** Redux */
import { Store } from "./RTK/Store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={Store}>
		<React.StrictMode>
			<RouterProvider router={Router} />
		</React.StrictMode>
	</Provider>
);
