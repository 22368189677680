import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import NotFoundPage from "./pages/notFoundPage/NotFoundPage";
import LoadingData from "../components/loadingData/LoadingData";

// Lazy-loaded components
const LazyRootLayout = React.lazy(() => import("../RootLayout"));
const LazyHome = React.lazy(() => import("./pages/Home"));
const LazyAboutPage = React.lazy(() => import("./pages/AboutPage"));
const LazyContactUs = React.lazy(() => import("./pages/ContactUs"));
const LazyPartners = React.lazy(() => import("./pages/Partners"));
const LazyProjects = React.lazy(() => import("./pages/Projects"));
const LazyServices = React.lazy(() => import("./pages/Services"));
const LazySolutions = React.lazy(() => import("./pages/Solutions"));
const LazyProjectDetails = React.lazy(() =>
	import("./pages/nestedPages/ProjectDetails")
);
const LazyServicesDetails = React.lazy(() =>
	import("./pages/nestedPages/ServicesDetails")
);
const LazySolutionsDetails = React.lazy(() =>
	import("./pages/nestedPages/SolutionsDetails")
);

// Reusable Suspense wrapper
const withSuspense = (Component) => (
	<Suspense fallback={<LoadingData />}>
		<Component />
	</Suspense>
);

export const Router = createBrowserRouter([
	{
		path: "/",
		element: withSuspense(LazyRootLayout),
		errorElement: <NotFoundPage />,
		children: [
			{ path: "/", index: true, element: withSuspense(LazyHome) },
			{ path: "/about_us", element: withSuspense(LazyAboutPage) },
			{ path: "/solutions", element: withSuspense(LazySolutions) },
			{ path: "/solutions/:id", element: withSuspense(LazySolutionsDetails) },
			{ path: "/services", element: withSuspense(LazyServices) },
			{ path: "/services/:id", element: withSuspense(LazyServicesDetails) },
			{ path: "/projects", element: withSuspense(LazyProjects) },
			{ path: "/projects/:id", element: withSuspense(LazyProjectDetails) },
			{ path: "/partners", element: withSuspense(LazyPartners) },
			{ path: "/contact_us", element: withSuspense(LazyContactUs) },
		],
	},
]);
