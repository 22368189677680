import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const SendContactUsThunk = createAsyncThunk(
	"contactUs/SendContactUsThunk",
	async (arg, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;

		try {
			const res = await axios.post(
				`https://api.raj.com.sa/api/public/contact_us`,
				arg
			);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.data);
		}
	}
);
