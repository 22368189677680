import { createSlice } from "@reduxjs/toolkit";
import { SocialLinksThunk } from "../Thunk/SocialLinksThunk";

let initState = {
	reload: false,
	socialLinkData: {},
};

const SocialLinksReducer = createSlice({
	name: "SocialLinks",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(SocialLinksThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(SocialLinksThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.socialLinkData = action.payload.data;
			})
			.addCase(SocialLinksThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.error.message;
			});
	},
});
export default SocialLinksReducer.reducer;
