import { createSlice } from "@reduxjs/toolkit";
import { ServicesPageThunk } from "../Thunk/ServicesPageThunk";
import { ShowOneServiceThunk } from "../Thunk/ShowOneServiceThunk";

const initState = {
	reload: true,
	servicesData: [],
	currentPage: {},
};

const ServicesReducer = createSlice({
	name: "Services",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// =======AllPagesThunk data===========
			.addCase(ServicesPageThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(ServicesPageThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.servicesData = action.payload?.data;
			})
			.addCase(ServicesPageThunk.rejected, (state, action) => {
				state.reload = false;
			})

			// =======AllPagesThunk data===========
			.addCase(ShowOneServiceThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(ShowOneServiceThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.currentPage = action.payload?.data;
			})
			.addCase(ShowOneServiceThunk.rejected, (state, action) => {
				state.reload = false;
			});
	},
});

export default ServicesReducer.reducer;
