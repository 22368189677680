import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const SiteLogoThunk = createAsyncThunk(
	"SiteLogo/SiteLogoThunk",
	async (_, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;
		try {
			const res = await axios.get(`https://api.raj.com.sa/public/site_logo`);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.data);
		}
	}
);
