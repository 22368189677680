import { createSlice } from "@reduxjs/toolkit";

import { MainPageThunk } from "../Thunk/MainPageThunk";

let initState = {
	reload: false,
	mainPageData: [],
};

const MainPageReducer = createSlice({
	name: "MainPage",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(MainPageThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(MainPageThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.mainPageData = action.payload.data;
			})
			.addCase(MainPageThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.payload;
			});
	},
});
export default MainPageReducer.reducer;
