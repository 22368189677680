import { createSlice } from "@reduxjs/toolkit";
import { ProjectsPageThunk } from "../Thunk/ProjectsPageThunk";
import { ShowOneProjectThunk } from "../Thunk/ShowOneProjectThunk";

const initState = {
	reload: true,
	projectsData: [],
	currentPage: {},
};

const ProjectsReducer = createSlice({
	name: "Projects",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// =======AllPagesThunk data===========
			.addCase(ProjectsPageThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(ProjectsPageThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.projectsData = action.payload?.data;
			})
			.addCase(ProjectsPageThunk.rejected, (state, action) => {
				state.reload = false;
			})

			.addCase(ShowOneProjectThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(ShowOneProjectThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.currentPage = action.payload?.data;
			})
			.addCase(ShowOneProjectThunk.rejected, (state, action) => {
				state.reload = false;
			});
	},
});

export default ProjectsReducer.reducer;
